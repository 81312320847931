<template>
  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#kennel"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">譲渡対象動物{{modeLabel}}</h1>
      </div>
      <div class="control-group">
        <button
          class="btn btn-secondary btn-lg me-2"
          @click='this.$router.back()'
        >戻る</button>
        <button
          v-if="mode === 'add'"
          class="btn btn-warning btn-lg"
          @click.prevent="submit"
        >＋追加する</button>
        <button
          v-else
          class="btn btn-warning btn-lg"
          @click.prevent="update"
        >更新する</button>
      </div>
    </div>
  </header>

  <main class="p-3">

    <div id="edit-animal-form" class="mb-5">

      <!--写真-->
      <div class="frontrow">
        <div class="avatar me-4">
          <label for="select-pics" class='preview'>
            <img :src="animalData.preview" v-if="animalData.preview">
            <img src="@/assets/ico_dog.png" v-else>
            <div class="guide">
              <span class="badge bg-warning text-dark">タップして写真を追加</span>
            </div>
          </label>
          <input
            type='file'
            ref='preview'
            id='select-pics'
            accept="image/*"
            @change='fileSelect'
          >
        </div>

        <!--PRIMARY-->
        <div class="primary">
          
          <!--名前-->
          <div class="input-group input-group-lg mb-2">
            <span class="input-group-text" style="width: 170px;">名前</span>
            <input
              type="text"
              ref="animalName"
              class="form-control"
              :class="{'is-invalid': error.animalName}"
              v-model="animalData.control_number"
              placeholder="名前または番号"
              required
            >
            <div class="invalid-feedback">名前を入力してください</div>
          </div>

          <!--現状の所有者-->
          <div class="input-group input-group-lg mb-2">
            <span class="input-group-text" style="width: 170px;">現状の所有者</span>
            <input
              type="text"
              ref="animalName"
              class="form-control"
              v-model="animalData.belongs"
              placeholder="現状の所有者"
              required
            >
          </div>

          <!--個体識別番号-->
          <div class="input-group input-group-lg mb-2">
            <span class="input-group-text" style="width: 170px;">
              <span>
                個体識別番号
              </span>
            </span>
            <input
              type="text"
              ref="animalNumber"
              class="form-control"
              v-model="animalData.microchip_no"
              placeholder="マイクロチップナンバー"
            >
          </div>

          <!--登録番号-->
          <div class="input-group input-group-lg mb-2">
            <span class="input-group-text" style="width: 170px;">
              <span>
                登録番号
              </span>
            </span>
            <input
              type="text"
              ref="my_number"
              class="form-control"
              v-model="animalData.my_number"
              placeholder="市区町村への届出番号"
            >
          </div>

          <!--種類-->
          <div class="input-group input-group-lg mb-2">
            <button
              class="btn btn-secondary dropdown-toggle"
              data-bs-toggle="dropdown"
              style="width: 170px; text-align: left;"
            >{{genusLabel}}</button>
            <ul class="dropdown-menu">
              <li><span class="dropdown-item" @click="setGenus('dog')">犬</span></li>
              <li><span class="dropdown-item" @click="setGenus('cat')">猫</span></li>
            </ul>
            <!--品種-->
            <select
              ref="species"
              class="form-select"
              :class="{'is-invalid': error.species}"
              v-model="animalData.animal_breed"
              required
            >
              <option value="" v-if="speciesList.length">選択してください</option>
              <option
                v-for="(sp, index) in speciesList"
                :key=index
                :value="sp.id"
              >{{sp.name}}</option>
            </select>
            <div class="invalid-feedback">種類を選択してください</div>
          </div>

          <!--性別-->
          <div class="input-group input-group-lg mb-2">
            <span class="input-group-text" style="width: 170px;">性別</span>
            <select
              ref="sex"
              class="form-select"
              :class="{'is-invalid': error.sex}"
              v-model="animalData.sex"
              required
            >
              <option
                value="male"
              >オス</option>
              <option
                value="female"
              >メス</option>
            </select>
            <div class="invalid-feedback">性別を選択してください</div>
          </div>

          <!--生年月日-->
          <div class="input-group input-group-lg mb-2">
            <span class="input-group-text" style="width: 170px;">生年月日</span>
            <input
              type="date"
              ref="birthDay"
              class="form-control"
              :class="{'is-invalid': error.birthDay}"
              v-model="animalData.birthday"
              required
            >
            <div class="invalid-feedback">生年月日を入力してください</div>
          </div>

          <!--毛色・柄-->
          <div class="input-group input-group-lg">
            <span class="input-group-text" style="width: 170px;">毛色・柄</span>
            <select
              ref="cololr"
              class="form-select"
              v-model="animalData.coat_color"
            >
              <option value="" v-if="colorList.length">選択してください</option>
              <option
                v-for="(color, index) in colorList"
                :key=index
                :value="color.id"
              >{{color.name}}</option>
            </select>
          </div>

        </div>
      </div>

      <!--SECONDARY-->
      <div class="secondary mb-5">

        <!--去勢-->
        <div class="input-group input-group-lg mb-2">
          <span class="input-group-text" style="width: 180px;">去勢</span>
          <select
            ref="cololr"
            class="form-select"
            v-model="animalData.IsFixed"
          >
            <option value="0" selected>未実施</option>
            <option value="1">実施済み</option>
          </select>
        </div>

        <!--遺伝性疾患有無-->
        <div class="input-group input-group-lg mb-2">
          <span class="input-group-text" style="width: 180px;">遺伝性疾患</span>
          <select
            ref="cololr"
            class="form-select"
            v-model="animalData.IsExistHereditaryDisease"
          >
            <option value="0" selected>なし</option>
            <option value="1">あり</option>
          </select>
        </div>

      </div>

    </div>


    <transition name="fade">
      <div class="processing" v-if="processing"></div>
    </transition>


  </main>
</template>

<script>
import speciesList from "@/assets/speciesList.js"
import colorCodeList from "@/assets/colorCodeList.js"

export default {
  data() {
    return {
      animalData: {
        IsFixed: 0,
        uploadFile: null,
        preview: null,
      },
      mode: "",
      speciesList: [],
      colorList: [],
      error: {},
    }
  },
  mounted() {
    this.$refs.animalName.focus()
    
    if(this.$route.params.id){
      //編集
      this.mode = "edit"
      this.animalData = this.$store.getters["Animal/container"]
      this.setGenus(this.animalData.animal_type)

      this.animalData["preview"] = this.animalData.thumbUrl
    }else{
      //新規追加
      this.mode = "add"
    }
  },
  methods: {
    setGenus(genus) {
      this.animalData.animal_type = genus
      this.speciesList = speciesList[genus]
      this.colorList = colorCodeList[genus]
    },
    fileSelect() {
      this.animalData.uploadFile = this.$refs.preview.files[0]
      this.animalData.preview = URL.createObjectURL(this.animalData.uploadFile)
    },
    validate() {
      let refs = this.$refs
      let array = {}
      Object.keys(refs).forEach(function(key) {
        if( refs[key].checkValidity() === false ){
          array[key] = true
        }
      })
      this.error = array
      return Object.keys(array).length > 0 ? false : true
    },
    submit() {
      // if(!this.validate())
      //   return

      if(confirm("譲渡対象動物に追加しますか？")){
        this.$store.dispatch('Mediate/add', this.animalData)
      }
    },
    update() {
      if(!this.validate())
        return

      if(confirm("更新しますか？")){
        this.$store.dispatch('Mediate/update', this.animalData)
      }
    }
  },
  computed: {
    genusLabel() {
      switch(this.animalData.animal_type) {
        case "dog":
          return "犬"
        case "cat":
          return "猫"
        default:
          return "種類"
      }
    },
    processing() {
      return this.$store.getters['Animal/processing']
    },
    modeLabel() {
      return this.mode === "add" ? "追加" : "更新"
    }
  },
  watch: {
    "animalData.animal_breed": function(newVal) {
      let species = speciesList[this.animalData.animal_type].find( row => row.id === newVal )
      this.animalData.animal_type_str = species.name
    },
    "animalData.coat_color": function(newVal) {
      let color = colorCodeList[this.animalData.animal_type].find( row => row.id === newVal )
      this.animalData.coat_color_str = color.name
    }
  }
}
</script>
