const colorCodeList = {
  dog: [
    { id: "01", name: "茶色" },
    { id: "02", name: "薄茶" },
    { id: "03", name: "褐色" },
    { id: "04", name: "赤茶" },
    { id: "05", name: "栗色" },
    { id: "06", name: "ベージュ" },
    { id: "07", name: "バフ" },
    { id: "08", name: "白" },
    { id: "09", name: "クリーム" },
    { id: "10", name: "イエロー" },
    { id: "11", name: "オレンジ" },
    { id: "12", name: "アプリコット" },
    { id: "13", name: "ブルー" },
    { id: "14", name: "ブルータン" },
    { id: "15", name: "スチールブルー" },
    { id: "16", name: "パープル" },
    { id: "17", name: "赤" },
    { id: "18", name: "黒" },
    { id: "19", name: "ブラックタン" },
    { id: "20", name: "セーブル" },
    { id: "21", name: "グレー" },
    { id: "22", name: "シルバー" },
    { id: "23", name: "ゴールド" },
    { id: "24", name: "ブリンドル" },
    { id: "25", name: "ファウンドマーキング" },
    { id: "26", name: "タン" },
    { id: "51", name: "黒・茶" },
    { id: "52", name: "白・茶" },
    { id: "53", name: "白・黒" },
    { id: "54", name: "薄茶・白" },
    { id: "55", name: "黒・グレー" },
    { id: "56", name: "薄茶・グレー" },
    { id: "57", name: "茶・グレー" },
    { id: "58", name: "ホワイト・セーブル" },
    { id: "59", name: "ホワイトグレー" },
    { id: "60", name: "シルバーグレー" },
    { id: "71", name: "三毛（白・黒・茶）" },
    { id: "72", name: "白・黒・灰" },
    { id: "81", name: "ブチ" },
    { id: "82", name: "胡麻" },
    { id: "83", name: "まだら" },
    { id: "84", name: "パーティカラー" },
    { id: "85", name: "虎毛" },
    { id: "86", name: "ソルトアンドペッパー" },
    { id: "87", name: "チョコレート" },
    { id: "99", name: "その他" },
  ],
  cat: [
    { id: "01", name: "茶" },
    { id: "02", name: "黒" },
    { id: "03", name: "白" },
    { id: "04", name: "シルバー" },
    { id: "05", name: "ゴールド" },
    { id: "06", name: "クリーム" },
    { id: "11", name: "白・茶" },
    { id: "12", name: "白・黒" },
    { id: "21", name: "茶トラ" },
    { id: "22", name: "キジトラ" },
    { id: "23", name: "サバトラ" },
    { id: "31", name: "三毛(白・黒・茶)" },
    { id: "99", name: "その他" },
  ]
}

export default colorCodeList;